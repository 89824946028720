<template>
  <el-drawer
    append-to-body
    size="800px"
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div slot="title" style="font-weight: bold; font-size: 18px">详情</div>
    <div class="ad-detail">
      <div>
        <h3>广告详情</h3>
        <div class="ad-detail-container" v-if="templateInfo">
          <div
            class="ad-part flex-row-start-center"
            v-for="(value, key) in templateInfo.adConfig"
            :key="key"
          >
            <div class="part-name">{{ value.propertyName }}</div>
            <div class="part-value">{{ value.ChineseText }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="creative-detail">
      <div>
        <h3>创意详情</h3>
        <div class="creative-detail-container" v-if="templateInfo">
          <div
            class="ad-part flex-row-start-center"
            v-for="(value, key) in templateInfo.creativityConfig"
            :key="key"
          >
            <div class="part-name">{{ value.propertyName }}</div>
            <div
              class="part-value flex-row-start-center"
              v-html="getValue(value, key)"
            ></div>
          </div>
          <div class="ad-part flex-row-start-center" v-if="creativeInfo">
            <div class="part-name" style="align-self: flex-start">素材内容</div>
            <div class="part-value">
              <div class="resource-info flex-row-start-start-wrap">
                <div
                  class="resource-item"
                  v-for="(item, index) in creativeInfo.materials"
                  :key="index"
                >
                  <!-- 横竖视频 -->
                  <template v-if="'video_id' in item">
                    <div class="part-one flex-row-start-start">
                      <div class='flex-center-center'>
                        <video
                          :src="`${item.video_id.url}`"
                          alt=""
                          controls
                          muted
                        /><span>{{ item.video_id.title }}</span>
                      </div>
                      <div v-if="item.material_id[0]" class='flex-center-center'>
                        <img
                          :src="`${item.material_id[0].resources}`"
                          alt=""
                        /><span>封面图</span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="part-two flex-row-start-start">
                      <!-- 横版大图 -->
                      <div
                        class="horizontal-version"
                        v-if="item.material_id.length === 1"
                      >
                        <img :src="item.material_id[0].resources.url" alt="" />
                        <span>{{ item.material_id[0].resources.title }}</span>
                      </div>
                      <!-- 横版三小图 -->
                      <template v-else>
                        <div
                          class="config-three-pic"
                          v-for="(img, index2) in item.material_id"
                          :key="index2"
                        >
                          <div class="config-three-pic-item">
                            <img :src="img.resources.url" alt="" />
                            <span>{{ img.resources.title }}</span>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getDetailInfo } from '@/api/tencent'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    keyValue: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      templateInfo: null,
      creativeInfo: null,
      groupInfo: null
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.initData()
        }
      }
    }
  },
  methods: {
    initData () {
      if (this.keyValue) {
        getDetailInfo({
          groupId: this.keyValue.groupId,
          creativeId: this.keyValue.creativeId
        }).then((res) => {
          const { creativeDetails, groupDetails } = res
          const [creativeInfo] = creativeDetails
          const [groupInfo] = groupDetails
          this.creativeInfo = creativeInfo
          this.groupInfo = groupInfo
          const {
            adqTemplate: { templateInfo }
          } = groupInfo
          this.templateInfo = JSON.parse(templateInfo)
        })
      }
    },
    handleClose () {
      this.$emit('close')
    },
    getValue (val, key) {
      if (key !== 'brandInfo') return `${val.ChineseText}`
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
@mixin flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ad-detail,
.creative-detail {
  font-size: 13px;
  > div {
    padding: 0 20px 20px;
  }
  h3 {
    margin-bottom: 20px;
  }
}
.ad-detail {
  border-bottom: 1px solid $borderColor;
}
.creative-detail {
  margin-top: 30px;
}
.ad-part {
  margin-bottom: 15px;
  .part-name {
    width: 30%;
    text-align: left;
    color: #626366;
  }
  .part-value {
    width: 70%;
    /deep/ img {
      width: 50px;
      height: 50px;
      border-radius: 6px;
    }
  }
}
.resource-info {
  .resource-item {
    margin: 0px 30px 20px 0px;
    .part-one {
      border-radius: 8px;
      background-color: #f5f6f7;
      > div {
        width: 200px;
        padding-top:20px;
        @include flex-column;
        > span {
          padding: 10px 0;
        }
        img,
        video {
          width: 150px !important;
          height: 200px !important;
          object-fit: cover;
        }
      }
    }
    .part-two {
      border-radius: 8px;
      background-color: #f5f6f7;
      margin: 0 10px 10px 0;
      .horizontal-version {
        @include flex-column;
        width: 200px;
        padding-top:20px;
        img,
        video {
          width: 150px;
          height: 200px;
          object-fit: cover;
        }
        span {
          padding: 10px 0;
        }
      }
      .config-three-pic {
        &-item {
          @include flex-column;
          padding-right: 10px;
          padding-top:20px;
          img,
          video {
            width: 150px;
            height: 200px;
            object-fit: cover;
          }
          span {
            padding: 10px 0;
          }
        }
      }
    }
  }
}
</style>
