<template>
<div class='ad-put-list'>
    <div class='ad-put-container'>
       <dy-table
          v-if='!isCheckDetail'
          :loading='tabConLoading'
          :dataList='putDataList'
          :rowList='putRowList'
          :showPagination="true"
          :page="putPage"
          :pageSize='putPageSize'
          :total="putTotal"
          :height="'700'"
          @pageChange="(page)=>{handlePageChange('putTable',page)}"
       >
        <template #filter>
            <el-button @click='initData' class='margin-right-ten'>刷新数据</el-button>
            <c-input  v-model='campaignName' class='margin-right-ten' style='width:300px' placeholder='请输入计划名称'>
                <el-button slot='append' icon='el-icon-search' @click="handlePageChange('putTable',1)"></el-button>
            </c-input>
            <el-button type='primary' @click='addNewAdqContent'>新增计划</el-button>
        </template>
        <template #wholeCampaignName={row}>
            <span title='点击跳转到广告和创意列表' @click='checkDetail(row.planId)' class='a-link line-overflow'>{{row.wholeCampaignName}}</span>
        </template>
          <template #planRule={row}>
            <span>{{row.planRule === 1 ? '平均分配' : '重复分配'}}</span>
          </template>
          <template #createRule={row}>
            <span>{{row.createRule === 1 ? '按素材': row.createRule === 2 ? '按文案' : '按定向'}}</span>
          </template>
         <template #status={row}>
             <span>
                 <el-tag :type='getTagType2(row.status).type'>{{getTagType2(row.status).text}}</el-tag>
             </span>
          </template>
        <template #dailyBudget={row}>
            <span >{{row.dailyBudget === 0 ? '不限' :`${Math.floor(row.dailyBudget/100)}元` }}</span>
        </template>
        <template #totalBudget={row}>
            <span>{{row.totalBudget === 0 ? '不限' :`${Math.floor(row.totalBudget/100)}元`}}</span>
        </template>
        <template #createdTime={row}>
            <span>{{row.createdTime|customTime}}</span>
        </template>
       </dy-table>
       <dy-table v-else
           :loading='dLoading'
           :dataList='dDataList'
           :rowList='dRowList'
           :showPagination="true"
           :page='dPage'
           :pageSize='dPageSize'
           :total='dTotal'
           height='650'
           @pageChange="(page)=>{handlePageChange('dTable',page)}"
       >
          <template #filter>
             <i class='el-icon-back' @click='(isCheckDetail = false,dPage = 1)' title='返回计划列表' style='font-size:18px;color:#ccc;font-weight:bold;cursor: pointer;margin-right:10px'></i>
              <el-button @click='getDetailData' class='margin-right-ten'>刷新数据</el-button>
              <c-input style='width:300px' v-model='name' placeholder='请输入账号名称或者计划名称'>
                  <el-button slot='append' @click='getDetailData' icon='el-icon-search'></el-button>
              </c-input>
          </template>
          <template #adqAdvertising={row}>
             <span title='点击查看广告详情' @click="handleDetail('ad',row)"  class='a-link line-overflow'>{{row.adqAdvertising.adName}}</span>
          </template>
          <template #adqUploadGroup={row}>
             <span  >{{row.adqUploadGroup.adgroupName}}</span>
          </template>
          <template #status={row}>
             <span>
                 <el-tag :type='getTagType(row.status).type'>{{getTagType(row.status).text}}</el-tag>
             </span>
          </template>
       </dy-table>
    </div>
    <ad-detail :show='showDrawer' @close='showDrawer = false' :keyValue='keyValue'></ad-detail>
</div>
</template>

<script>
import { getAdqPlan, getPlanDetails } from '@/api/tencent'
import { formatTime } from '@/assets/js/utils'
import AdDetail from './component/ad-detail'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      name: '', // 账号名称或推广计划名称-模糊查询
      dPage: 0,
      dTotal: 0,
      putPage: 1,
      putTotal: 0,
      planId: null,
      dDataList: [],
      dPageSize: 15,
      keyValue: null, // 传给广告详情或者创意详情的id
      putDataList: [],
      putPageSize: 15,
      dLoading: false,
      campaignName: '', // 计划名称
      adminUserId: null,
      showDrawer: false,
      isCheckDetail: false, // 是否点击了跳转
      tabConLoading: false,
      putRowList: [{
        label: '计划名称',
        prop: 'wholeCampaignName',
        slot: true,
        width: 300
      }, {
        label: '账户名称',
        prop: 'accountName',
        width: 200
      }, {
        label: '状态',
        prop: 'status',
        slot: true
      },{
        label:'分配规则',
        prop:'planRule',
        slot:true
      },{
        label:'创建规则',
        prop:'createRule',
        slot:true
      },{
        label: '错误信息',
        prop: 'messageCn',
        popover: true
      } ,{
        label: '日预算',
        prop: 'dailyBudget',
        slot: true,
        width: '100'
      }, {
        label: '总预算',
        prop: 'totalBudget',
        slot: true,
        width: '100'
      }, {
        label: '创建时间',
        prop: 'createdTime',
        slot: true,
        width: 150
      }],
      dRowList: [{
        label: '广告名称',
        prop: 'adqAdvertising',
        slot: true
      }, {
        label: '创意名称',
        prop: 'adqUploadGroup',
        slot: true
      }, {
        label: '状态',
        prop: 'status',
        slot: true
      }, {
        label: '错误信息',
        prop: 'messageCn',
        popover: true
      }]
    }
  },
  mounted () {
    this.initData()
  },
  filters: {
    customTime (time) {
      return formatTime(time)
    }
  },
  watch: {
    isCheckDetail (newV) {
      if (!newV) {
        this.initData()
      }
    }
  },
  computed: {
    ...mapGetters(['userList'])
  },
  methods: {
    initData () {
      this.tabConLoading = true
      getAdqPlan({
        page: this.putPage,
        pageSize: this.putPageSize,
        campaignName: this.campaignName
      }).then((res) => {
        this.putDataList = res.list
        this.putTotal = res.total
      }).finally(() => {
        this.tabConLoading = false
      })
    },
    getDetailData () {
      this.dLoading = true
      getPlanDetails({
        page: this.dPage,
        pageSize: this.dPageSize,
        name: this.name,
        planId: this.planId
      }).then((res) => {
        res.list.forEach(item => {
          item.status = item.adqAdvertising.status !== 3 ? item.adqAdvertising.status : item.adqAdvertising.systemStatus ? item.adqAdvertising.systemStatus : null
          item.messageCn = `${item.adqAdvertising.messageCn ? `${item.adqAdvertising.messageCn} ||` : ''} ${item.adqUploadAdcreative.messageCn ? `${item.adqUploadAdcreative.messageCn} ||` : ''} ${item.adqUploadGroup.messageCn ? item.adqUploadGroup.messageCn : ''} `
        })
        this.dDataList = res.list
        this.dTotal = res.total
      }).finally(() => {
        this.dLoading = false
      })
    },
    handlePageChange (type = 'putTable', page) {
      if (type === 'putTable') {
        this.putPage = page
        this.initData()
      }
      if (type === 'dTable') {
        this.dPage = page
        this.getDetailData()
      }
    },
    addNewAdqContent () {
      this.$router.push({
        name: 'AdPut'
      })
    },
    checkDetail (planId) {
      this.isCheckDetail = true
      this.planId = planId
      this.getDetailData()
    },
    handleDetail (type, data) {
      const { adqUploadAdcreative, adqUploadGroup } = data
      this.keyValue = {
        groupId: adqUploadGroup.id,
        creativeId: adqUploadAdcreative.id
      }
      this.showDrawer = true
    },
    getTagType2 (status) {
      if (!status) {
        return {
          type: 'info',
          text: '未知'
        }
      }
      if (status === 1) {
        return {
          type: 'info',
          text: '待上传'
        }
      }
      if (status === 2) {
        return {
          type: 'danger',
          text: '上传失败'
        }
      }
      if (status === 3) {
        return {
          type: 'success',
          text: '成功'
        }
      }
    },
    getTagType (status) {
      if (!status) {
        return {
          type: 'info',
          text: '未知'
        }
      }
      if (status === 1) {
        return {
          type: 'info',
          text: '待上传'
        }
      }
      if (status === 2) {
        return {
          type: 'danger',
          text: '上传失败'
        }
      }
      if (status === 'AD_STATUS_NORMAL') {
        return {
          type: 'success',
          text: '有效'
        }
      }
      if (status === 'AD_STATUS_PENDING') {
        return {
          type: 'warning',
          text: '待审核'
        }
      }
      if (status === 'AD_STATUS_DENIED') {
        return {
          type: 'danger',
          text: '审核不通过'
        }
      }

      if (status === 'AD_STATUS_PARTIALLY_PENDING') {
        return {
          type: 'warning',
          text: '部分审核中'
        }
      }
      if (status === 'AD_STATUS_PARTIALLY_NORMAL') {
        return {
          type: 'warning',
          text: '部分有效'
        }
      }
      if (status === 'AD_STATUS_PREPARE') {
        return {
          type: 'info',
          text: '准备中'
        }
      }
      if (status === 'AD_STATUS_DELETED') {
        return {
          type: 'danger',
          text: '已删除'
        }
      }
      if (status === 'AD_STATUS_INVALID') {
        return {
          type: 'danger',
          text: '异常'
        }
      }
    },
  },
  components: { AdDetail }
}
</script>
<style lang='scss' scoped>
.a-link{
    color: #40a9ff;
    cursor: pointer;
    text-decoration: underline;

}
</style>
